import React from 'react'
import SingleScrew from "../../assets/images/DeltaPDJPG/SingleScrewPump.jpg";

function SingleScrewPump() {
  return (
    <div className='prod_image'>
        <img src={SingleScrew} alt="" className='pro_img'/>
    </div>
  )
}

export default SingleScrewPump

    
  