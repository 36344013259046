import React from 'react'
import DosingElectronics from "../../assets/images/DosingElectronic.jpg";

function DosingElectronic() {
  return (
    <div className='prod_image'>
    <img src={DosingElectronics} alt="" className='pro_img'/>
</div>
  )
}

export default DosingElectronic