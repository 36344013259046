import React from 'react'
import ExternalGear from "../../assets/images/DeltaPDJPG/ExternalGearPump.jpg";


function ExternalGearPump() {
  return (
    <div className='prod_image'>
    <img src={ExternalGear} alt="" className='pro_img'/>
</div>
  )
}

export default ExternalGearPump