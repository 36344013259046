import React from 'react';
import {
  
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';



import { Link } from 'react-router-dom'
import logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <div className='w-full mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300 footerbg'>
      <div>
        <Link to='/' className=''>
          <img src={logo} alt="" width={150} height={90} />Maxtreme Engineering (P)Ltd
        </Link> <div className='flex justify-between md:w-[75%] my-6'>
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
         
       
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6 '>
        <div>
          <h6 className='font-bold text-white-400'>Address:</h6>
         <p>
         Maxtreme Engineering (P) Ltd<br/>
No: 110, 2nd Floor, Mugalivakkam Main Road,<br/>
Madhanandapuram, Porur,<br/>
Chennai - 600 125
         </p>
     
</div>
      
        <div className='pl-5  '>
          <h6 className='font-bold text-white-400'>Get in Touch</h6>
          <Link >
Phone: 044 43556213 | +91 9092759901 | +91 9566117047</Link><br/>
<Link to="mailto:"  >Email: sales@maxtreme.in | info@maxtreme</Link>
        </div>
     

      </div>
    </div>
  );
};

export default Footer;
