import React from 'react'
import DosingHydraulics from "../../assets/images/DosingHydraulic.jpg";

function DosingHydraulic() {
  return (
    <div className='prod_image'>
    <img src={DosingHydraulics} alt="" className='pro_img'/>
</div>
  )
}

export default DosingHydraulic