import React from 'react';

import DosingMechanicals from "../../assets/images/DosingMechanical.jpg";

function DosingMechanical() {
  return (
    <div className='prod_image'>
    <img src={DosingMechanicals} alt="" className='pro_img'/>
</div>
  )
}

export default DosingMechanical