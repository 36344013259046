import React from 'react'
  
  function Contact() {
      return (
          <>
              <div className="w-full text-[#fff] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center contactbg">
                  
              <h1 className='md:text-5xl sm:text-6xl text-4xl font-bold text-center'>
            Maxtreme Engineering (P)Ltd
          </h1>
              </div>
          </>
      )
  }
  
  export default Contact