import React from 'react'
import IndustrialMotors from "../../assets/images/IndustrialMotors.jpg";

function industrialMotors() {
  return (
    <div className='prod_image'>
        <img src={IndustrialMotors} alt="" className='pro_img'/>
    </div>
  )
}

export default industrialMotors