import React from 'react';
// import Maxtreme from '../../../../assets/images/logo.png';
import STM from '../../../../assets/images/logos/stm.jpg';
import Delta_logo from '../../../../assets/images/logos/Delta_logo.png';
import Hindustan from '../../../../assets/images/logos/hindustan.jpg';

function Partners() {
  return (
    <>
     <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>EXCLUSIVE CHANNEL PARTNERS FOR</h1>
    <div className='w-full py-[2rem] px-4 bg-white'>

      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              
          <img className='w-full mx-auto mt-[-3rem] bg-white' src={STM} alt="/" />
                </div>
          
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <img className='w-full mx-auto mt-[3rem] bg-white' src={Hindustan} alt="/" />
              
              </div><div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
          <img className='w-full mx-auto mt-[1rem] bg-white' src={Delta_logo} alt="/" />
               </div>
      </div>
    </div>
    </>
  );
}

export default Partners;
