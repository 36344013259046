import React from 'react';
import DosingPlungers from "../../assets/images/DosingPlunger.jpg";

function DosingPlunger() {
  return (
    <div className='prod_image'>
    <img src={DosingPlungers} alt="" className='pro_img'/>
</div>
  )
}

export default DosingPlunger