import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import './Navbar.css';

const Navbar = ({ menus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleSubmenu = (e) => {
    e.preventDefault();
    const parent = e.currentTarget.parentNode;
    const submenu = parent.querySelector('.submenu');
    submenu.classList.toggle('active');
  };

  const renderMenu = (menu) => {
    return (
      <li key={menu.id}>
        <Link to={menu.path}>{menu.name}</Link>
        {menu.children && (
          <ul className="submenu">
            {menu.children.map((submenu) => (
              <li key={submenu.id}>
                <Link to={submenu.path}>{submenu.name}</Link>
                {submenu.children && renderSubmenu(submenu.children)}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  const renderSubmenu = (submenus) => {
    return (
      <ul className="sub-submenu">
        {submenus.map((submenu) => (
          <li key={submenu.id}>
            <Link to={submenu.path}>{submenu.name}</Link>
            {submenu.children && renderSubmenu(submenu.children)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <nav className="navbar">
    <Link to='/' className='logo'>
      <img src={logo} alt="Logo" style={{ width: 70, height: 40, paddingLeft: 20 }} />
      Maxtreme
    </Link>
    <button className="menu-toggle" onClick={toggleMenu}>
      <span className="bar"></span>
      <span className="bar"></span>
      <span className="bar"></span>
    </button>
    <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
      {menus.map((menu) => (
        <li key={menu.id}>
          <Link to={menu.path}>{menu.name}</Link>
          {menu.children && (
            <ul className="submenu">
              {menu.children.map((submenu) => (
                <li key={submenu.id}>
                  <Link to={submenu.path}>{submenu.name}</Link>
                  {submenu.children && renderSubmenu(submenu.children)}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </nav>
  );
};

export default Navbar;
