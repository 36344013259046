import React from 'react'
import Centrifugal from "../../assets/images/DeltaPDJPG/CentrifugalPump.jpg";

function CentrifugalPumps() {
  return (
    <div className='prod_image'>
    <img src={Centrifugal} alt="" className='pro_img'/>
</div>
  )
}

export default CentrifugalPumps