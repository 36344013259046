import React from 'react'

import Bearing from '../../assets/images/DeltaPDJPG/Bearing.png';

function Bearings() {
  return (
    <div className='prod_image'>

    
    <img src={Bearing} alt="" className='pro_img'/> 

</div>
  )
}

export default Bearings