import React from 'react'
// import STM from '../../assets/images/logos/stm.jpg';

import STMGearbox from "../../assets/images/DeltaPDJPG/STMGearbox.png";

function Gearbox() {
  return (
    
   
      <div className='prod_image'>
   

        {/* <img className=' mx-auto mt-[1rem] img-fluid' src={STM} alt="/" /> */}
        
        <img src={STMGearbox} alt="" className='pro_img'/>  


    </div>
  )
}

export default Gearbox