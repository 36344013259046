import React from 'react'
import RotaryTrochoidalGear from "../../assets/images/DeltaPDJPG/RotaryTrochoidalGearPump.jpg";

function RotaryTrochoidalGearPump() {
  return (
    <div className='prod_image'>
    <img src={RotaryTrochoidalGear} alt="" className='pro_img'/>
</div>
  )
}

export default RotaryTrochoidalGearPump