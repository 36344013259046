import React from 'react'
import AoddPump from "../../assets/images/AoddPump.jpg";

function Aodd() {
  return (
    <div className='prod_image'>
        <img src={AoddPump} alt="" className='pro_img'/>
    </div>
  )
}

export default Aodd