import React from 'react'
// import valves from '../../assets/images/Valves/Cast-Steel-Gate-Valve.jpg'
import Chains from '../../assets/images/DeltaPDJPG/IndustrialChains.png'

function Industrialchains() {
  return (
   
  
  <div className='prod_image'>
   

        {/* <img className=' mx-auto mt-[1rem] ' src={valves} alt="/" /> */}
        
        <img src={Chains} alt="" className='pro_img'/>  


    </div>
  )
}

export default Industrialchains