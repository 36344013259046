import React from 'react';

import maxtreme_banner from '../../../../assets/images/pexels-pixabay-236722.jpg';
import './slider.css'; // Import the CSS file

const Banner = () => {
  return (
    <div className="banner">
      <div className="overlay"></div>
      <img src={maxtreme_banner} alt="Website Banner" style={{ maxHeight: '550px', width: '100%' }} />
      {/* <div className="content">
        <h1>Welcome to Our Website!</h1>
        <p>Discover amazing content and explore our services.</p>
      </div> */}
    </div>
  );
}

export default Banner;
