import React from 'react'
import Fluorolinedlogo from '../../assets/images/Fluorolined/images.jpg'
import fluorolinedwaterpump from '../../assets/images/Fluorolined/fluorolined-water-pump.png'
import pvdfcentrifugalpump from '../../assets/images/Fluorolined/pvdf-centrifugal-pump.jpg'
import pvdflinedprocesspump from '../../assets/images/Fluorolined/pvdf-lined-process-pump.png'

function Fluorolined() {
  return (
    <div>
    <img className=' mx-auto mt-[1rem] ' src={Fluorolinedlogo} alt="/" />
 
      <div className='w-full py-[2rem] px-4 bg-white'>


<div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
<div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={fluorolinedwaterpump} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>Fluorolined Water Pump</figcaption>  
    </div>
    <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={pvdfcentrifugalpump} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>PVDF Centrifugal Pump</figcaption>  
    </div>
    <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={pvdflinedprocesspump} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>PVDF Lined Process Pump</figcaption>  
    </div>
</div>
</div>


</div>
  )
}

export default Fluorolined