import React from 'react'
import InternalExternalGear from "../../assets/images/DeltaPDJPG/InternalGearPump.jpg";


function InternalShuttleBlockPump() {
  return (
    <div className='prod_image'>
    <img src={InternalExternalGear} alt="" className='pro_img'/>
</div>
  )
}

export default InternalShuttleBlockPump