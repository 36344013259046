import React from 'react';
import Maxtreme from '../../../../assets/images/pexels-pixabay-256297.jpg';
import './Welcome.css'; // Import CSS file for additional styling

function Welcome() {
  return (
    <div>
      <section className="about-section">
        <div className="about-container">
          <div className="grid-container">
            <div className="image-container">
              <img className='max-w-full h-auto image-animation' src={Maxtreme} alt="/" />
            </div>
            <div className="paragraph-container">
              <p className='p-5'>
                At MAXTREME, we specialize in engineering, project management, installation, and commissioning services, catering to a diverse range of industrial and turnkey projects. As authorized distributors and service providers for fluid handling and power transmission equipment, we offer comprehensive solutions to meet your needs.
              </p>
              <p className='p-5'>
                Driven by a passion for excellence, our organization is led by a team of seasoned professionals who bring a wealth of experience and expertise to every endeavor. With a focus on quality and customer satisfaction, we are committed to delivering exceptional results that exceed expectations.
              </p>
              <p className='p-5'>
                Join us at MAXTREME, where we turn visions into reality and set new standards of excellence in the industry.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Welcome;
