import React from 'react'
// import valves from '../../assets/images/Valves/Cast-Steel-Gate-Valve.jpg'
import valves from '../../assets/images/DeltaPDJPG/IndustrialValves.jpg'

function Industrialvalves() {
  return (
   
  
  <div className='prod_image'>
   

        {/* <img className=' mx-auto mt-[1rem] ' src={valves} alt="/" /> */}
        
        <img src={valves} alt="" className='pro_img'/>  


    </div>
  )
}

export default Industrialvalves