import React from 'react'
import TwinScrew from "../../assets/images/DeltaPDJPG/TwinScrewPump.jpg";


function TwinScrewPump() {
  return (
    <div className='prod_image'>
    <img src={TwinScrew} alt="" className='pro_img'/>
</div>
  )
}

export default TwinScrewPump