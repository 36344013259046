import React from 'react';



import Slider from './slider/slider';
import Partners from './Partners/Partners';
import Welcome from './Welcome/Welcome';

function Home() {
  return (
    <>
<Slider />
      <Welcome />
     <Partners />
    </>
  )
}

export default Home