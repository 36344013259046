import React from 'react'
import ThreeScrew from "../../assets/images/DeltaPDJPG/ThreeScrewPump.jpg";


function ThreeScrewpump() {
  return (
    <div className='prod_image'>
    <img src={ThreeScrew} alt="" className='pro_img'/>
</div>
  )
}

export default ThreeScrewpump