import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home/Home';
import Contact from './components/pages/Contact';

import Bearings from './components/pages/Bearings';
import Gearbox from './components/pages/Gearbox';
import IndustrialMotors from './components/pages/industrialMotors';
import Aodd from './components/pages/Aodd';
import SingleScrewPump from './components/pages/SingleScrewPump';
import TwinScrewPump from './components/pages/TwinScrewPump';
import ThreeScrewpump from './components/pages/ThreeScrewpump';
import InternalShuttleBlockPump from './components/pages/InternalShuttleBlockPump';
import ExternalGearPump from './components/pages/ExternalGearPump';
import RotaryTrochoidalGearPump from './components/pages/RotaryTrochoidalGearPump';
import Fluorolined from './components/pages/Fluorolined';
import Dusaut from './components/pages/Dusaut';
import CentrifugalPumps from './components/pages/CentrifugalPumps.jsx';

import IndustrialChains from './components/pages/Industrialchains';
import Industrialvalves from './components/pages/Industrialvalves';



import DosingHydraulic from './components/pages/DosingHydraulic';
import DosingPlunger from './components/pages/DosingPlunger';
import DosingElectronic from './components/pages/DosingElectronic';
import DosingMechanical from './components/pages/DosingMechanical';


function App() {
  const menus = [
    // Your JSON data here
    
      {
        "name": "Home",
        "id": 1,
        "path":"/"
      },
      
      {
        "name": "Products",
        "id": 2,
        "children": [
          {
            "name": "Power Transmission",
            "id": 5,
            "children": [
              { "name": "Bearings",
              "id": 10,
              "path": "/Bearings" },
              { "name": "Gearbox",
              "id": 11,
              "path": "/Gearbox" },
              { "name": "Industrial Motors",
              "id": 12,
              "path": "/IndustrialMotors" },
              { "name": "Inductrial Chains",
              "id": 13,
              "path": "/industrialChains" }
            ]
          },
          {
            "name": "Fluid Handling",
            "id": 6,
            "children":[
                {"name":"Aodd Pumps",
                "id": 14,
                "path": "/Aodd"},
                {
                  "name": "Positive Displacement Pumps",
                  "id": 15,
                  "children":[
                    { 
                    "name": "Single Screw Pump",
                    "id": 19,
                    "path": "/SingleScrewPump" 
                  },
                    { "name": "Twin Screw Pump",
                    "id": 20,
                    "path": "/TwinScrewPump" },
                    { "name": "Three Screw pump",
                    "id": 21,
                    "path": "/ThreeScrewpump" },
                    { "name": "Internal & Shuttle Block Pump",
                    "id": 22,
                    "path": "/InternalShuttleBlockPump" },
                    { "name": "External Gear Pump",
                    "id": 23,
                    "path": "/ExternalGearPump" },
                    { "name": "Rotary Trochoidal Gear Pump",
                    "id": 24,
                    "path": "/RotaryTrochoidalGearPump" }
                    
                      
                  ]
              },
              {
                "name": "Centrifugal Pumps",
                "path": "/CentrifugalPumps",
                "id": 16
              },
              {
                "name": "Dosing Pumps",
                "id": 17,
                "children": [
                  { "name": "Plunger Type",
                  "id": 25,
                  "path": "/DosingPlunger" },
                  { "name": "Hydraulic Type",
                  "id": 26,
                  "path": "/DosingHydraulic" },
                  { "name": "Mechanical Type",
                  "id": 27,
                  "path": "/DosingMechanical" },
                  { "name": "Electronic Type",
                  "id": 28,
                  "path": "/DosingElectronic" }
                ]
              },
              {
                "name": "Industrial Valves",
                "path": "/industrialvalves",
                "id": 18
              }
                
            ]
        }
        ]
      },
      {
        "name": "Projects",
        "path": "/",
        "id": 3
      },
      {
        "name": "Contact us",
        "path": "/",
        "id": 4
        
      }
    
  ];
  return (
    <div className='container-fluid'>
 
 <Router>
 {/* <Navbar menuItems={MenuItems} /> */}
 <Navbar menus={menus} />
        <Routes>
        <Route path='/' element={<Home />} />
       
        
        <Route path='/bearings' element={<Bearings />} />
        <Route path='/CentrifugalPumps' element={<CentrifugalPumps />} />
        <Route path='/DosingHydraulic' element={<DosingHydraulic />} />
        <Route path='/DosingElectronic' element={<DosingElectronic />} />
        <Route path='/DosingMechanical' element={<DosingMechanical />} />
        <Route path='/DosingPlunger' element={<DosingPlunger />} />
        <Route path='/gearbox' element={<Gearbox />} />
        <Route path='/industrialMotors' element={<IndustrialMotors />} />
        <Route path='/industrialChains' element={<IndustrialChains />} />
        <Route path='/aodd' element={<Aodd />} />
        <Route path='/SingleScrewPump' element={<SingleScrewPump />} />
        <Route path='/TwinScrewPump' element={<TwinScrewPump />} />
        <Route path='/ThreeScrewpump' element={<ThreeScrewpump />} />
        <Route path='/InternalShuttleBlockPump' element={<InternalShuttleBlockPump/>} />
        <Route path='/ExternalGearPump' element={<ExternalGearPump  />} />
        <Route path='/RotaryTrochoidalGearPump' element={<RotaryTrochoidalGearPump />} />
        <Route path='/fluorolined' element={<Fluorolined />} />
        <Route path='/dusaut' element={<Dusaut />} />
        
        <Route path='/industrialvalves' element={<Industrialvalves />} />
          <Route path='/contact' element={<Contact />} />
         
         
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
