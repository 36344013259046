import React from 'react'
import flowmeter from '../../assets/images/DusAut/logo.png'
import flowmeter1 from '../../assets/images/DusAut/31BWClEjYfL.jpg'
import flowmeter2 from '../../assets/images/DusAut/6.jpg'
import flowmeter3 from '../../assets/images/DusAut/electromagnetic-flow-meter-500x500.jpg'
import flowmeter4 from '../../assets/images/DusAut/unnamed.jpg'

function Dusaut() {
  return (
    <div>
    <img className=' mx-auto mt-[1rem] ' src={flowmeter} alt="/" />
 
      <div className='w-full py-[2rem] px-4 bg-white'>


<div className='max-w-[1240px] mx-auto grid md:grid-cols-4 gap-8'>
<div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={flowmeter1} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>Fluorolined Water Pump</figcaption>  
    </div>
    <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={flowmeter2} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>PVDF Centrifugal Pump</figcaption>  
    </div>
    <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={flowmeter3} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>PVDF Lined Process Pump</figcaption>  
    </div>
    <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
<img className='w-full mx-auto mt-[1rem] bg-white' src={flowmeter4} alt="/" />
<figcaption className='md:text-4xl sm:text-3xl text-2xl font-bold p-4 text-center'>PVDF Lined Process Pump</figcaption>  
    </div>
</div>
</div>


</div>
  )
}

export default Dusaut